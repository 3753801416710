import { useRouter } from 'next/router';
import Head from 'next/head';
import Image from 'next/image';

import Layout from '#layout/index';
import {
 Button, H2, H4, Paragraph 
} from '#styleguide/index';

const NotFoundPage = () => {
  const router = useRouter();

  return (
    <>
      <Head>
        <title>Crack The Code | Academia online de programación y tecnología</title>
        <meta
          name="description"
          content="Academia de ciencia de la computación. Cursos y talleres de programación, robótica educativa, desarrollo de videojuegos y filmación para niños ¡Descubre nuestros cursos!"
        />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Layout mainType="full_variant">
        <section className="landing-404">
          <div className="landing-404__content-button">
            <div className="landing-404__content">
              <H2>Algo salió mal...</H2>
              <H4>Error 404 Page Not Found</H4>
              <Paragraph size="21">
                Esta página no existe o fue eliminada :( Te sugerimos regresar al Home
              </Paragraph>
            </div>
            <Button size="big" onClick={() => router.push('/')}>
              Volver a Home
            </Button>
          </div>
          <Image
            className="landing-404__image"
            src="/image-404.webp"
            alt="404"
            width="530"
            height="528"
          />
        </section>
      </Layout>
    </>
  );
};

export default NotFoundPage;
